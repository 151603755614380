import LocalizedStrings from 'react-localization';


let strings = new LocalizedStrings({
  en: {
    save: 'Submit Form',
    saveLabel : 'Save',
    cancel : 'Cancel',
    close: 'Close',
    Error: 'Error : ',
    attachFile: 'Attach File',
    addPhoto: 'Add Photo',
    goBack: 'Click to go back',
    identifiedError: 'Please fix the errors identified and try submitting again.',
    misMatchSubmission: `Couldn't find the matching submission.`,
    successfulSubmission: 'Successfully submitted/Queued for submission.',
    preview: '- Preview',
    loading: 'Loading...Please wait',
    uploading: 'Uploading..',
    uploadingFile: 'Uploading File. Click Close to Continue',
    uploadFailed: 'Upload failed. Click retry to reupload.',
    reUploadMessage: 'Upload failed. Delete and reupload file.',
    fileSizeError: 'Error: File is larger than {0} MB',
    retry: 'Retry',
    networkError: 'Network Error',
    fileUploadError: 'File Upload Error Found',
    fileUploading: 'Please wait file is uploading',
    authenticationFailed: 'Authentication Failed',
    confirmation: 'Confirmation',
    deleteConfirmationMsg: 'Are you sure you want to delete this item ?',
    yes: 'Yes',
    no: 'No',
    requiredfieldsMissing: 'is missing required fields',
    uploadFiles: 'Upload Files',
    attachOrDrop: 'Attach or Drop Files',
    deleteIcon: 'Delete Icon',
    discard: 'Discard',
    discardMessage: 'Are you sure you want to discard this form?',
    print: 'PRINT',
    imageAltText: 'Form Image',
    comboxErrorMessage: 'Invalid value'
  }
});

export default strings;